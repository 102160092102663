
import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  if (action.type === `CHANGE_LANGUAGE`) {
    return Object.assign({}, state, {
      lang: action.payload.lang,
    })
  }

  if (action.type === `SET_LOADED`) {
    return Object.assign({}, state, {
      loaded: true,
    })
  }

  if (action.type === `MUSIC`) {
    return Object.assign({}, state, {
      musicState: action.payload.musicState,
    })
  }

  return state
}

const initialState = { lang: 'ge', loaded: false, musicState: 'stopped' }

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore