import "./src/styles/normalize.scss"
import "./src/styles/variables.scss"
import "./src/styles/global.scss"
import "@egjs/flicking-plugins/dist/arrow.css";
import wrapWithProvider from "./wrap-with-provider";

export const wrapRootElement = wrapWithProvider;

export const onClientEntry = () => {
  window.onload = () => {
    const event = new Event('musicClick');
    const homeScreen = document.querySelector('.home-video-main');
    const homeButton = document.querySelector('.enter');
    const homeButtonMobile = document.querySelector('.enter-mobile');

    homeScreen.addEventListener('transitionend', () => {
        homeScreen.classList.add('displayed-none');
    })

    homeButton.addEventListener('click', () => {
        window.dispatchEvent(event);
        homeScreen.classList.add('hidden');
    })
    homeButtonMobile.addEventListener('click', () => {
        window.dispatchEvent(event);
        homeScreen.classList.add('hidden');
    })
    }

    const loadHomeImage = (item) => {
        if (!item || !item.href) return;

        let img = new Image();
        if (item.dataset) {
            img.srcset = item.dataset.srcset || '';
            img.sizes = item.dataset.sizes || '';
        }

        img.src = item.href;
        img.className = 'reveal';
        if (img.complete) addImg();
        else img.onload = addImg;

        function addImg() {
            item.addEventListener('click', function(e) { e.preventDefault(); }, false);

            item.appendChild(img).addEventListener('animationend', function(e) {
                var pImg = item.querySelector && item.querySelector('img.preview');
                if (pImg) {
                e.target.alt = pImg.alt || '';
                item.removeChild(pImg);
                item.removeChild(img)
                e.target.classList.remove('reveal');
                }

            });

            item.style.backgroundImage = `url(${img.src})`;

            }
    }

    const homeImage = document.querySelector('.home-video-main--img.progressive');
    loadHomeImage(homeImage);
}

export const onRouteUpdate = ({}) => {
    if (window.FB) {
        window.FB.init({
            appId            : 'facebook-jssdk',
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v11.0'
        });
  };
}
